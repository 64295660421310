const step1 = document.getElementById('step-1');
let timeout;
step1.classList.add('how__step--1--animated');
step1.addEventListener('mouseenter', () => {
  if (step1.classList.contains('how__step--1--transition')) {
    return;
  }
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    step1.classList.remove('how__step--1--animated');
    timeout = setTimeout(() => {
      step1.classList.add('how__step--1--transition');
    }, 10);
  }, 10);
});
step1.addEventListener('mouseleave', () => {
  if (!step1.classList.contains('how__step--1--transition')) {
    clearTimeout(timeout);
    return;
  }
  clearTimeout(timeout);
  step1.classList.remove('how__step--1--transition');
  timeout = setTimeout(() => {
    step1.classList.add('how__step--1--animated');
  }, 1500);
});
const checkNav = () => {
  const topbar = document.querySelector('.topbar');
  const isSticky = topbar.classList.contains('topbar--sticky');

  if (!isSticky && window.scrollY > 100) {
    topbar.classList.add('topbar--sticky');
  }

  if (isSticky && window.scrollY <= 100) {
    topbar.classList.remove('topbar--sticky');
  }
}
const checkCTA = () => {
  const cta = document.querySelector('.cta');
  const isActive = cta.classList.contains('cta--active');
  const distance = cta.getBoundingClientRect().y - window.innerHeight;
  const center = window.innerHeight * 1.2;
  const clamped = clamp(distance, -center, 0);
  const degree = (clamped/-center) * 30 - 15;

  cta.style.setProperty('--cta-rotation', `${degree}deg`);

  if (degree > -5 && degree < 5 && !isActive) {
    cta.classList.add('cta--active');
  }
  if ((degree <= -5 || degree >= 5) && isActive) {
    cta.classList.remove('cta--active');
  }
}
checkNav();
window.addEventListener('scroll', () => {
  checkNav();
  checkCTA();
});

const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

window.openModal = () => {
  document.querySelector('.modal').classList.add('modal--active');

  setTimeout(() => {
    document.querySelector('.modal input').focus();
  }, 500);
}
window.closeModal = () => {
  document.querySelector('.modal').classList.remove('modal--active');
}

window.signUp = (event) => {
  event.preventDefault();
  const button = document.querySelector('.modal .button');
  const email = document.querySelector('.modal input').value;

  if (button.classList.contains('button--loading')) {
    return;
  }

  if (!validateEmail(email)) {
    document.querySelector('.modal').classList.add('modal--error');
    return;
  }

  if (document.querySelector('.wrong')) {
    document.querySelector('.wrong').remove();
  }

  button.classList.add('button--loading');
  
  setTimeout(() => {
    fetch('/subscribe', {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        'email': email
      })
    }).then((response) => {
      if (response.ok || response.code === 'duplicate_parameter') {
        document.querySelector('.modal').classList.add('modal--success');
        document.querySelector('.modal__content').innerHTML = `
            <header class="modal__header"><h2>Thank you for your interest!</h2>
            <p>You will receive access soon. We're excited to have you join us.
            <br />Stay tuned!</p></header>`;
        return;
      }

      throw new Error("Something went wrong");
    }).catch(() => {
      const div = document.createElement("div");
      div.classList = 'wrong';
      div.innerText = 'Something went wrong';
      document.querySelector('.modal__form').append(div);
      setTimeout(() => {
        button.classList.remove('button--loading');
      }, 500);
    });
  }, 500);
}

window.validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

window.inputChanged = () => {
  if (document.querySelector('.modal--error')) {
    document.querySelector('.modal').classList.remove('modal--error')
  }
}
